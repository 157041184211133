.skin-green .main-header .navbar {
	background-color: #00a65a
}

.skin-green .main-header .navbar .nav > li > a {
	color: #fff
}

.skin-green .main-header .navbar .nav > li > a:hover, .skin-green .main-header .navbar .nav > li > a:active, .skin-green .main-header .navbar .nav > li > a:focus, .skin-green .main-header .navbar .nav .open > a, .skin-green .main-header .navbar .nav .open > a:hover, .skin-green .main-header .navbar .nav .open > a:focus, .skin-green .main-header .navbar .nav > .active > a {
	background: rgba(0, 0, 0, 0.1);
	color: #f6f6f6
}

.skin-green .main-header .navbar .sidebar-toggle {
	color: #fff
}

.skin-green .main-header .navbar .sidebar-toggle:hover {
	color: #f6f6f6;
	background: rgba(0, 0, 0, 0.1)
}

.skin-green .main-header .navbar .sidebar-toggle {
	color: #fff
}

.skin-green .main-header .navbar .sidebar-toggle:hover {
	background-color: #008d4c
}

@media (max-width: 767px) {
	.skin-green .main-header .navbar .dropdown-menu li.divider {
		background-color: rgba(255, 255, 255, 0.1)
	}
	.skin-green .main-header .navbar .dropdown-menu li a {
		color: #fff
	}
	.skin-green .main-header .navbar .dropdown-menu li a:hover {
		background: #008d4c
	}
}

.skin-green .main-header .logo {
	background-color: #008d4c;
	color: #fff;
	border-bottom: 0 solid transparent
}

.skin-green .main-header .logo:hover {
	background-color: #008749
}

.skin-green .main-header li.user-header {
	background-color: #00a65a
}

.skin-green .content-header {
	background: transparent
}

.skin-green .wrapper, .skin-green .main-sidebar, .skin-green .left-side {
	background-color: #222d32
}

.skin-green .user-panel > .info, .skin-green .user-panel > .info > a {
	color: #fff
}

.skin-green .sidebar-menu > li.header {
	color: #4b646f;
	background: #1a2226
}

.skin-green .sidebar-menu > li > a {
	border-left: 3px solid transparent
}

.skin-green .sidebar-menu > li:hover > a, .skin-green .sidebar-menu > li.active > a, .skin-green .sidebar-menu > li.menu-open > a {
	color: #fff;
	background: #1e282c
}

.skin-green .sidebar-menu > li.active > a {
	border-left-color: #00a65a
}

.skin-green .sidebar-menu > li > .treeview-menu {
	margin: 0 1px;
	background: #2c3b41
}

.skin-green .sidebar a {
	color: #b8c7ce
}

.skin-green .sidebar a:hover {
	text-decoration: none
}

.skin-green .sidebar-menu .treeview-menu > li > a {
	color: #8aa4af
}

.skin-green .sidebar-menu .treeview-menu > li.active > a, .skin-green .sidebar-menu .treeview-menu > li > a:hover {
	color: #fff
}

.skin-green .sidebar-form {
	border-radius: 3px;
	border: 1px solid #374850;
	margin: 10px 10px
}

.skin-green .sidebar-form input[type="text"], .skin-green .sidebar-form .btn {
	box-shadow: none;
	background-color: #374850;
	border: 1px solid transparent;
	height: 35px
}

.skin-green .sidebar-form input[type="text"] {
	color: #666;
	border-top-left-radius: 2px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 2px
}

.skin-green .sidebar-form input[type="text"]:focus, .skin-green .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
	background-color: #fff;
	color: #666
}

.skin-green .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
	border-left-color: #fff
}

.skin-green .sidebar-form .btn {
	color: #999;
	border-top-left-radius: 0;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 0
}
