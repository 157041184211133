/*@import "~antd/lib/alert/style/index.css";
@import "~antd/lib/anchor/style/index.css";
@import "~antd/lib/auto-complete/style/index.css";
@import "~antd/lib/avatar/style/index.css";
@import "~antd/lib/back-top/style/index.css";
@import "~antd/lib/badge/style/index.css";
@import "~antd/lib/breadcrumb/style/index.css";
@import "~antd/lib/calendar/style/index.css";
@import "~antd/lib/card/style/index.css";
@import "~antd/lib/carousel/style/index.css";
@import "~antd/lib/cascader/style/index.css";
@import "~antd/lib/checkbox/style/index.css";
@import "~antd/lib/collapse/style/index.css";
@import "~antd/lib/date-picker/style/index.css";
@import "~antd/lib/divider/style/index.css";
@import "~antd/lib/drawer/style/index.css";
@import "~antd/lib/form/style/index.css";
@import "~antd/lib/grid/style/index.css";
@import "~antd/lib/layout/style/index.css";
@import "~antd/lib/list/style/index.css";
@import "~antd/lib/locale-provider/style/index.css";
@import "~antd/lib/mention/style/index.css";
@import "~antd/lib/menu/style/index.css";
@import "~antd/lib/modal/style/index.css";
@import "~antd/lib/notification/style/index.css";
@import "~antd/lib/pagination/style/index.css";
@import "~antd/lib/steps/style/index.css";
@import "~antd/lib/tabs/style/index.css";
@import "~antd/lib/time-picker/style/index.css";
@import "~antd/lib/timeline/style/index.css";
@import "~antd/lib/transfer/style/index.css";
*/
@import "~antd/lib/switch/style/index.css";
@import "~antd/lib/tree/style/index.css";
@import "~antd/lib/rate/style/index.css";
@import "~antd/lib/tag/style/index.css";
@import "~antd/lib/progress/style/index.css";
@import "~antd/lib/tree-select/style/index.css";
@import "~antd/lib/skeleton/style/index.css";
@import "~antd/lib/message/style/index.css";
@import "~antd/lib/message/style/index.css";
@import "~antd/lib/tooltip/style/index.css";
@import "~antd/lib/upload/style/index.css";
@import "~antd/lib/slider/style/index.css";
@import "~antd/lib/radio/style/index.css";
@import "~antd/lib/popover/style/index.css";
@import "~antd/lib/checkbox/style/index.css";
@import "~antd/lib/icon/style/index.css";
@import "~antd/lib/dropdown/style/index.css";
@import "~antd/lib/table/style/index.css";
@import "~antd/lib/pagination/style/index.css";
@import "~antd/lib/select/style/index.css";
@import "~antd/lib/button/style/index.css";
@import "~antd/lib/affix/style/index.css";
@import "~antd/lib/spin/style/index.css";
@import "~antd/lib/modal/style/index.css";
@import "~antd/lib/notification/style/index.css";
@import "~antd/lib/popover/style/index.css";
@import "~antd/lib/date-picker/style/index.css";
@import "~antd/lib/input/style/index.css";
@import "~antd/lib/input-number/style/index.css";

.ant-dropdown {
	font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.65);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	left: -9999px;
	top: -9999px;
	z-index: 1050;
	display: block;
}

.ant-dropdown-wrap {
	position: relative;
}

.ant-dropdown-wrap .ant-btn > .anticon-down {
	display: inline-block;
	font-size: 12px;
	font-size: 10px \9
;
	-webkit-transform: scale(0.83333333) rotate(0deg);
	-ms-transform: scale(0.83333333) rotate(0deg);
	transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-wrap .ant-btn > .anticon-down {
	font-size: 12px;
}

.ant-dropdown-wrap .anticon-down:before {
	-webkit-transition: -webkit-transform .2s;
	transition: -webkit-transform .2s;
	transition: transform .2s;
	transition: transform .2s, -webkit-transform .2s;
}

.ant-dropdown-wrap-open .anticon-down:before {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
	display: none;
}

.ant-dropdown-menu {
	outline: none;
	position: relative;
	list-style-type: none;
	padding: 4px 0;
	margin: 0;
	text-align: left;
	background-color: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	background-clip: padding-box;
}

.ant-dropdown-menu-item-group-title {
	color: rgba(0, 0, 0, 0.45);
	padding: 5px 12px;
	-webkit-transition: all .3s;
	transition: all .3s;
}

.ant-dropdown-menu-submenu-popup {
	position: absolute;
	z-index: 1050;
}

.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	padding: 5px 12px;
	margin: 0;
	clear: both;
	font-size: 14px;
	font-weight: normal;
	color: rgba(0, 0, 0, 0.65);
	white-space: nowrap;
	cursor: pointer;
	-webkit-transition: all .3s;
	transition: all .3s;
	line-height: 22px;
}

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child {
	min-width: 12px;
	margin-right: 8px;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
	color: rgba(0, 0, 0, 0.65);
	display: block;
	padding: 5px 12px;
	margin: -5px -12px;
	-webkit-transition: all .3s;
	transition: all .3s;
}

.ant-dropdown-menu-item > a:focus,
.ant-dropdown-menu-submenu-title > a:focus {
	text-decoration: none;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
	color: #1890ff;
	background-color: #e6f7ff;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
	background-color: #e6f7ff;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
	height: 1px;
	overflow: hidden;
	background-color: #e8e8e8;
	line-height: 0;
	margin: 4px 0;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
	position: absolute;
	right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	font-style: normal;
	color: rgba(0, 0, 0, 0.45);
	display: inline-block;
	font-size: 12px;
	font-size: 10px \9
;
	-webkit-transform: scale(0.83333333) rotate(0deg);
	-ms-transform: scale(0.83333333) rotate(0deg);
	transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	font-size: 12px;
}

.ant-dropdown-menu-submenu-title {
	padding-right: 26px;
}

.ant-dropdown-menu-submenu-vertical {
	position: relative;
}

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
	top: 0;
	left: 100%;
	position: absolute;
	min-width: 100%;
	margin-left: 4px;
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	color: rgba(0, 0, 0, 0.25);
}

.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}

.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}

.ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
.ant-dropdown-link .anticon:not(.anticon-ellipsis) {
	display: inline-block;
	font-size: 12px;
	font-size: 10px \9
;
	-webkit-transform: scale(0.83333333) rotate(0deg);
	-ms-transform: scale(0.83333333) rotate(0deg);
	transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
:root .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
	font-size: 12px;
}

.ant-dropdown-button {
	white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-dropdown-button .anticon:not(.anticon-ellipsis) {
	display: inline-block;
	font-size: 12px;
	font-size: 10px \9
;
	-webkit-transform: scale(0.83333333) rotate(0deg);
	-ms-transform: scale(0.83333333) rotate(0deg);
	transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-button .anticon:not(.anticon-ellipsis) {
	font-size: 12px;
}

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
	background: #001529;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
	color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow:after {
	color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
	color: #fff;
	background: transparent;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
	background: #1890ff;
	color: #fff;
}

.ant-table-wrapper {
	zoom: 1;
}

.ant-table-wrapper:before,
.ant-table-wrapper:after {
	content: "";
	display: table;
}

.ant-table-wrapper:after {
	clear: both;
}

.ant-table {
	font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.65);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	clear: both;
}

.ant-table-body {
	-webkit-transition: opacity .3s;
	transition: opacity .3s;
}

.ant-table-empty .ant-table-body {
	overflow: auto !important;
}

.ant-table table {
	width: 100%;
	border-collapse: collapse;
	text-align: left;
	border-radius: 4px 4px 0 0;
}

.ant-table-thead > tr > th {
	background: #fafafa;
	-webkit-transition: background .3s ease;
	transition: background .3s ease;
	text-align: left;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	border-bottom: 1px solid #e8e8e8;
}

.ant-table-thead > tr > th[colspan] {
	text-align: center;
}

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
	font-size: 12px;
	cursor: pointer;
	color: #bfbfbf;
	-webkit-transition: all .3s;
	transition: all .3s;
	width: 28px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	text-align: center;
}

.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -5px;
	margin-left: -6px;
}

.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
	color: #1890ff;
}

.ant-table-thead > tr > th .ant-table-column-sorter {
	position: absolute;
	right: 6px;
	top: 50%;
	width: 14px;
	height: 17px;
	margin-top: -8.5px;
	text-align: center;
	color: #bfbfbf;
	-webkit-transition: all .3s;
	transition: all .3s;
}

.ant-table-thead > tr > th .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter-down {
	display: inline-block;
	font-size: 12px;
	font-size: 11px \9
;
	-webkit-transform: scale(0.91666667) rotate(0deg);
	-ms-transform: scale(0.91666667) rotate(0deg);
	transform: scale(0.91666667) rotate(0deg);
	line-height: 4px;
	height: 4px;
	-webkit-transition: all .3s;
	transition: all .3s;
	display: block;
}

:root .ant-table-thead > tr > th .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter-down {
	font-size: 12px;
}

.ant-table-thead > tr > th .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter-down.on {
	color: #1890ff;
}

.ant-table-thead > tr > th .ant-table-column-sorter-down {
	margin-top: 4px;
}

.ant-table-thead > tr > th.ant-table-column-has-actions {
	position: relative;
	background-clip: padding-box;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
	color: rgba(0, 0, 0, 0.45);
	background: #e5e5e5;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
	color: rgba(0, 0, 0, 0.45);
	background: #e5e5e5;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
	color: rgba(0, 0, 0, 0.65);
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
	cursor: pointer;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
	background: #f2f2f2;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
	background: #f2f2f2;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
	color: rgba(0, 0, 0, 0.45);
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-align-right.ant-table-align-right.ant-table-column-has-sorters,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-align-right.ant-table-align-right.ant-table-column-has-filters {
	padding-right: 30px;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-align-right.ant-table-align-right.ant-table-column-has-sorters.ant-table-column-has-filters {
	padding-right: 54px;
}

.ant-table-thead > tr > th .ant-table-column-sorters:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: transparent;
	-webkit-transition: all .3s;
	transition: all .3s;
}

.ant-table-thead > tr > th .ant-table-column-sorters:hover:before {
	background: rgba(0, 0, 0, 0.04);
}

.ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorter {
	right: 34px;
}

.ant-table-thead > tr > th.ant-table-column-has-sorters {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-table-thead > tr:first-child > th:first-child {
	border-top-left-radius: 4px;
}

.ant-table-thead > tr:first-child > th:last-child {
	border-top-right-radius: 4px;
}

.ant-table-thead > tr:not(:last-child) > th[colspan] {
	border-bottom: 0;
}

.ant-table-tbody > tr > td {
	border-bottom: 1px solid #e8e8e8;
	-webkit-transition: all .3s, border 0s;
	transition: all .3s, border 0s;
}

.ant-table-thead > tr,
.ant-table-tbody > tr {
	-webkit-transition: all .3s, height 0s;
	transition: all .3s, height 0s;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
	background: #e6f7ff;
}

.ant-table-thead > tr:hover {
	background: none;
}

.ant-table-footer {
	padding: 16px 16px;
	background: #fafafa;
	border-radius: 0 0 4px 4px;
	position: relative;
	border-top: 1px solid #e8e8e8;
}

.ant-table-footer:before {
	content: '';
	height: 1px;
	background: #fafafa;
	position: absolute;
	top: -1px;
	width: 100%;
	left: 0;
}

.ant-table.ant-table-bordered .ant-table-footer {
	border: 1px solid #e8e8e8;
}

.ant-table-title {
	padding: 16px 0;
	position: relative;
	top: 1px;
	border-radius: 4px 4px 0 0;
}

.ant-table.ant-table-bordered .ant-table-title {
	border: 1px solid #e8e8e8;
	padding-left: 16px;
	padding-right: 16px;
}

.ant-table-title + .ant-table-content {
	position: relative;
	border-radius: 4px 4px 0 0;
	overflow: hidden;
}

.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
	border-radius: 0;
}

.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
	border-radius: 0;
}

.ant-table-tbody > tr.ant-table-row-selected td {
	background: #fafafa;
}

.ant-table-thead > tr > th.ant-table-column-sort {
	background: #f5f5f5;
}

.ant-table-tbody > tr > td.ant-table-column-sort {
	background: rgba(0, 0, 0, 0.01);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	padding: 16px 16px;
	word-break: break-word;
	-ms-word-break: break-all;
}

.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
	margin-right: -15px;
}

.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
	text-align: center;
	min-width: 62px;
	width: 62px;
}

.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
	margin-right: 0;
}

.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
	text-align: center;
	min-width: 50px;
	width: 50px;
}

.ant-table-header {
	background: #fafafa;
	overflow: hidden;
}

.ant-table-header table {
	border-radius: 4px 4px 0 0;
}

.ant-table-loading {
	position: relative;
}

.ant-table-loading .ant-table-body {
	background: #fff;
	opacity: 0.5;
}

.ant-table-loading .ant-table-spin-holder {
	height: 20px;
	line-height: 20px;
	left: 50%;
	top: 50%;
	margin-left: -30px;
	position: absolute;
}

.ant-table-loading .ant-table-with-pagination {
	margin-top: -20px;
}

.ant-table-loading .ant-table-without-pagination {
	margin-top: 10px;
}

.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
	border: 1px solid #e8e8e8;
	border-right: 0;
	border-bottom: 0;
}

.ant-table-bordered.ant-table-empty .ant-table-placeholder {
	border-left: 1px solid #e8e8e8;
	border-right: 1px solid #e8e8e8;
}

.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
	border-bottom: 0;
}

.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
	border-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
	border-top: 0;
}

.ant-table-bordered.ant-table-fixed-header .ant-table-placeholder {
	border: 0;
}

.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
	border-bottom: 1px solid #e8e8e8;
}

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
	border-right: 1px solid #e8e8e8;
}

.ant-table-placeholder {
	position: relative;
	padding: 16px 16px;
	background: #fff;
	border-bottom: 1px solid #e8e8e8;
	text-align: center;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.45);
	z-index: 1;
}

.ant-table-placeholder .anticon {
	margin-right: 4px;
}

.ant-table-pagination.ant-pagination {
	margin: 16px 0;
	float: right;
}

.ant-table-filter-dropdown {
	min-width: 96px;
	margin-left: -8px;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-table-filter-dropdown .ant-dropdown-menu {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 4px 4px 0 0;
}

.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
	max-height: 400px;
	overflow-x: hidden;
}

.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
	padding-right: 0;
}

.ant-table-filter-dropdown .ant-dropdown-menu-sub {
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title:after {
	color: #1890ff;
	font-weight: bold;
	text-shadow: 0 0 2px #bae7ff;
}

.ant-table-filter-dropdown .ant-dropdown-menu-item {
	overflow: hidden;
}

.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
	border-radius: 0;
}

.ant-table-filter-dropdown-btns {
	overflow: hidden;
	padding: 7px 8px;
	border-top: 1px solid #e8e8e8;
}

.ant-table-filter-dropdown-link {
	color: #1890ff;
}

.ant-table-filter-dropdown-link:hover {
	color: #40a9ff;
}

.ant-table-filter-dropdown-link:active {
	color: #096dd9;
}

.ant-table-filter-dropdown-link.confirm {
	float: left;
}

.ant-table-filter-dropdown-link.clear {
	float: right;
}

.ant-table-selection-select-all-custom {
	margin-right: 4px !important;
}

.ant-table-selection .anticon-down {
	color: #bfbfbf;
	-webkit-transition: all .3s;
	transition: all .3s;
}

.ant-table-selection-menu {
	min-width: 96px;
	margin-top: 5px;
	margin-left: -30px;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-table-selection-menu .ant-action-down {
	color: #bfbfbf;
}

.ant-table-selection-down {
	cursor: pointer;
	padding: 0;
	display: inline-block;
	line-height: 1;
}

.ant-table-selection-down:hover .anticon-down {
	color: #666;
}

.ant-table-row-expand-icon {
	cursor: pointer;
	display: inline-block;
	width: 17px;
	height: 17px;
	text-align: center;
	line-height: 14px;
	border: 1px solid #e8e8e8;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: #fff;
}

.ant-table-row-expanded:after {
	content: '-';
}

.ant-table-row-collapsed:after {
	content: '+';
}

.ant-table-row-spaced {
	visibility: hidden;
}

.ant-table-row-spaced:after {
	content: '.';
}

.ant-table-row[class*="ant-table-row-level-0"] .ant-table-selection-column > span {
	display: inline-block;
}

tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
	background: #fbfbfb;
}

tr.ant-table-expanded-row .ant-table-wrapper {
	margin: -16px -16px -17px;
}

.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
	margin-right: 8px;
}

.ant-table-scroll {
	overflow: auto;
	overflow-x: hidden;
}

.ant-table-scroll table {
	width: auto;
	min-width: 100%;
}

.ant-table-body-inner {
	height: 100%;
}

.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
	position: relative;
	background: #fff;
}

.ant-table-fixed-header .ant-table-body-inner {
	overflow: scroll;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
	overflow: scroll;
	padding-bottom: 20px;
	margin-bottom: -20px;
	opacity: 0.9999;
}

.ant-table-fixed-left,
.ant-table-fixed-right {
	position: absolute;
	top: 0;
	overflow: hidden;
	-webkit-transition: -webkit-box-shadow 0.3s ease;
	transition: -webkit-box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
	border-radius: 0;
}

.ant-table-fixed-left table,
.ant-table-fixed-right table {
	width: auto;
	background: #fff;
}

.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
	border-radius: 0;
}

.ant-table-fixed-left {
	left: 0;
	-webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
	box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.ant-table-fixed-left .ant-table-header {
	overflow-y: hidden;
}

.ant-table-fixed-left .ant-table-body-inner {
	margin-right: -20px;
	padding-right: 20px;
}

.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
	padding-right: 0;
}

.ant-table-fixed-left,
.ant-table-fixed-left table {
	border-radius: 4px 0 0 0;
}

.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
	border-top-right-radius: 0;
}

.ant-table-fixed-right {
	right: 0;
	-webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
	box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.ant-table-fixed-right,
.ant-table-fixed-right table {
	border-radius: 0 4px 0 0;
}

.ant-table-fixed-right .ant-table-expanded-row {
	color: transparent;
	pointer-events: none;
}

.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
	border-top-left-radius: 0;
}

.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-footer {
	padding: 12px 8px;
}

.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
	padding: 12px 8px;
}

.ant-table-small {
	border: 1px solid #e8e8e8;
	border-radius: 4px;
}

.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-footer {
	padding: 8px 8px;
}

.ant-table-small > .ant-table-title {
	border-bottom: 1px solid #e8e8e8;
	top: 0;
}

.ant-table-small > .ant-table-content > .ant-table-body {
	margin: 0 8px;
}

.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
	border: 0;
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
	padding: 8px 8px;
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
	background-color: #fff;
	border-bottom: 1px solid #e8e8e8;
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
	background-color: rgba(0, 0, 0, 0.01);
}

.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
	padding: 0;
}

.ant-table-small > .ant-table-content .ant-table-header {
	background-color: #fff;
}

.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
	border-bottom: 0;
}

.ant-table-small.ant-table-bordered {
	border-right: 0;
}

.ant-table-small.ant-table-bordered .ant-table-title {
	border: 0;
	border-bottom: 1px solid #e8e8e8;
	border-right: 1px solid #e8e8e8;
}

.ant-table-small.ant-table-bordered .ant-table-content {
	border-right: 1px solid #e8e8e8;
}

.ant-table-small.ant-table-bordered .ant-table-footer {
	border: 0;
	border-top: 1px solid #e8e8e8;
	border-right: 1px solid #e8e8e8;
}

.ant-table-small.ant-table-bordered .ant-table-footer:before {
	display: none;
}

.ant-table-small.ant-table-bordered .ant-table-placeholder {
	border-left: 0;
	border-bottom: 0;
	border-right: 0;
}

.ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
	border-right: none;
}

.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
	border-right: 1px solid #e8e8e8;
}

.ant-table-small.ant-table-bordered .ant-table-fixed-right {
	border-left: 1px solid #e8e8e8;
	border-right: 1px solid #e8e8e8;
}

.ant-input {
	font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-variant: tabular-nums;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	display: inline-block;
	padding: 4px 11px;
	width: 100%;
	height: 32px;
	font-size: 14px;
	line-height: 1.5;
	color: rgba(0, 0, 0, .65);
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	-webkit-transition: all .3s;
	transition: all .3s;
}

/*
	Loader
 */

.ant-spin {
	font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.65);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	color: #1890ff;
	vertical-align: middle;
	text-align: center;
	opacity: 0;
	position: absolute;
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	display: none;
}

.ant-spin-spinning {
	opacity: 1;
	position: static;
	display: inline-block;
}

.ant-spin-nested-loading {
	position: relative;
}

.ant-spin-nested-loading > div > .ant-spin {
	display: block;
	position: absolute;
	height: 100%;
	max-height: 360px;
	width: 100%;
	z-index: 4;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -10px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
	position: absolute;
	top: 50%;
	width: 100%;
	padding-top: 5px;
	text-shadow: 0 1px 2px #fff;
}

.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
	margin-top: -20px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
	margin: -7px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
	padding-top: 2px;
}

.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
	margin-top: -17px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
	margin: -16px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
	padding-top: 11px;
}

.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
	margin-top: -26px;
}

.ant-spin-container {
	position: relative;
	-webkit-transition: opacity .3s;
	transition: opacity .3s;
	zoom: 1;
}

.ant-spin-container:before,
.ant-spin-container:after {
	content: "";
	display: table;
}

.ant-spin-container:after {
	clear: both;
}

.ant-spin-blur {
	pointer-events: none;
	user-select: none;
	overflow: hidden;
	opacity: 0.5;
	-webkit-filter: blur(0.5px);
	filter: blur(0.5px);
	/* autoprefixer: off */
}

.ant-spin-blur:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #fff;
	opacity: 0.3;
	-webkit-transition: all .3s;
	transition: all .3s;
	z-index: 10;
}

.ant-spin-tip {
	color: rgba(0, 0, 0, 0.45);
}

.ant-spin-dot {
	position: relative;
	display: inline-block;
	font-size: 20px;
	width: 20px;
	height: 20px;
}

.ant-spin-dot i {
	width: 9px;
	height: 9px;
	border-radius: 100%;
	background-color: #1890ff;
	-webkit-transform: scale(0.75);
	-ms-transform: scale(0.75);
	transform: scale(0.75);
	display: block;
	position: absolute;
	opacity: 0.3;
	-webkit-animation: antSpinMove 1s infinite linear alternate;
	animation: antSpinMove 1s infinite linear alternate;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.ant-spin-dot i:nth-child(1) {
	left: 0;
	top: 0;
}

.ant-spin-dot i:nth-child(2) {
	right: 0;
	top: 0;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.ant-spin-dot i:nth-child(3) {
	right: 0;
	bottom: 0;
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.ant-spin-dot i:nth-child(4) {
	left: 0;
	bottom: 0;
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

.ant-spin-dot-spin {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-animation: antRotate 1.2s infinite linear;
	animation: antRotate 1.2s infinite linear;
}

.ant-spin-sm .ant-spin-dot {
	font-size: 14px;
	width: 14px;
	height: 14px;
}

.ant-spin-sm .ant-spin-dot i {
	width: 6px;
	height: 6px;
}

.ant-spin-lg .ant-spin-dot {
	font-size: 32px;
	width: 32px;
	height: 32px;
}

.ant-spin-lg .ant-spin-dot i {
	width: 14px;
	height: 14px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
	display: block;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ */
	.ant-spin-blur {
		background: #fff;
		opacity: 0.5;
	}
}

@-webkit-keyframes antSpinMove {
	to {
		opacity: 1;
	}
}

@keyframes antSpinMove {
	to {
		opacity: 1;
	}
}

@-webkit-keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg);
	}
}

@keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg);
	}
}

/*
	Modal
 */
.ant-modal {
	font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.65);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	width: auto;
	margin: 0 auto;
	top: 100px;
	padding-bottom: 24px;
}

.ant-modal-wrap {
	position: fixed;
	overflow: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}

.ant-modal-title {
	margin: 0;
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.85);
}

.ant-modal-content {
	position: relative;
	background-color: #fff;
	border: 0;
	border-radius: 4px;
	background-clip: padding-box;
	-webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-modal-close {
	cursor: pointer;
	border: 0;
	background: transparent;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	-webkit-transition: color .3s;
	transition: color .3s;
	color: rgba(0, 0, 0, 0.45);
	outline: 0;
	padding: 0;
}

.ant-modal-close-x {
	display: block;
	font-style: normal;
	vertical-align: baseline;
	text-align: center;
	text-transform: none;
	text-rendering: auto;
	width: 56px;
	height: 56px;
	line-height: 56px;
	font-size: 16px;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
	color: #444;
	text-decoration: none;
}

.ant-modal-header {
	padding: 16px 24px;
	border-radius: 4px 4px 0 0;
	background: #fff;
	color: rgba(0, 0, 0, 0.65);
	border-bottom: 1px solid #e8e8e8;
}

.ant-modal-body {
	padding: 24px;
	font-size: 14px;
	line-height: 1.5;
	word-wrap: break-word;
}

.ant-modal-footer {
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	border-radius: 0 0 4px 4px;
}

.ant-modal-footer button + button {
	margin-left: 8px;
	margin-bottom: 0;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	opacity: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-modal-mask {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.65);
	height: 100%;
	z-index: 1000;
	filter: alpha(opacity=50);
}

.ant-modal-mask-hidden {
	display: none;
}

.ant-modal-open {
	overflow: hidden;
}

.ant-modal-centered {
	text-align: center;
}

.ant-modal-centered:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	width: 0;
}

.ant-modal-centered .ant-modal {
	display: inline-block;
	vertical-align: middle;
	top: 0;
	text-align: left;
}

@media (max-width: 767px) {
	.ant-modal {
		width: auto !important;
		margin: 10px;
	}
	.ant-modal-centered .ant-modal {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
}

.ant-modal-confirm .ant-modal-header {
	display: none;
}

.ant-modal-confirm .ant-modal-close {
	display: none;
}

.ant-modal-confirm .ant-modal-body {
	padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper {
	zoom: 1;
}

.ant-modal-confirm-body-wrapper:before,
.ant-modal-confirm-body-wrapper:after {
	content: "";
	display: table;
}

.ant-modal-confirm-body-wrapper:after {
	clear: both;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.4;
	display: block;
	overflow: hidden;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
	margin-left: 38px;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.65);
	margin-top: 8px;
}

.ant-modal-confirm-body > .anticon {
	font-size: 22px;
	margin-right: 16px;
	float: left;
}

.ant-modal-confirm .ant-modal-confirm-btns {
	margin-top: 24px;
	float: right;
}

.ant-modal-confirm .ant-modal-confirm-btns button + button {
	margin-left: 8px;
	margin-bottom: 0;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
	color: #f5222d;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
	color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
	color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
	color: #52c41a;
}

/*
	Notification
 */

.ant-notification {
	font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.65);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	position: fixed;
	z-index: 1050;
	width: 384px;
	max-width: calc(100vw - 32px);
	margin-right: 24px;
}

.ant-notification-topLeft,
.ant-notification-bottomLeft {
	margin-left: 24px;
	margin-right: 0;
}

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
	-webkit-animation-name: NotificationLeftFadeIn;
	animation-name: NotificationLeftFadeIn;
}

.ant-notification-close-icon {
	font-size: 14px;
	cursor: pointer;
}

.ant-notification-notice {
	padding: 16px 24px;
	border-radius: 4px;
	-webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	background: #fff;
	line-height: 1.5;
	position: relative;
	margin-bottom: 16px;
	overflow: hidden;
}

.ant-notification-notice-message {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.85);
	margin-bottom: 8px;
	line-height: 24px;
	display: inline-block;
}

.ant-notification-notice-message-single-line-auto-margin {
	width: calc(384px - 24px * 2 - 24px - 48px - 100%);
	background-color: transparent;
	pointer-events: none;
	display: block;
	max-width: 4px;
}

.ant-notification-notice-message-single-line-auto-margin:before {
	content: '';
	display: block;
}

.ant-notification-notice-description {
	font-size: 14px;
}

.ant-notification-notice-closable .ant-notification-notice-message {
	padding-right: 24px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
	font-size: 16px;
	margin-left: 48px;
	margin-bottom: 4px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
	margin-left: 48px;
	font-size: 14px;
}

.ant-notification-notice-icon {
	position: absolute;
	font-size: 24px;
	line-height: 24px;
	margin-left: 4px;
}

.ant-notification-notice-icon-success {
	color: #52c41a;
}

.ant-notification-notice-icon-info {
	color: #1890ff;
}

.ant-notification-notice-icon-warning {
	color: #faad14;
}

.ant-notification-notice-icon-error {
	color: #f5222d;
}

.ant-notification-notice-close {
	position: absolute;
	right: 22px;
	top: 16px;
	color: rgba(0, 0, 0, 0.45);
	outline: none;
}

a.ant-notification-notice-close:focus {
	text-decoration: none;
}

.ant-notification-notice-close:hover {
	color: rgba(0, 0, 0, 0.67);
}

.ant-notification-notice-btn {
	float: right;
	margin-top: 16px;
}

.ant-notification .notification-fade-effect {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-notification-fade-enter,
.ant-notification-fade-appear {
	opacity: 0;
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-notification-fade-leave {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
	-webkit-animation-name: NotificationFadeIn;
	animation-name: NotificationFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
	-webkit-animation-name: NotificationFadeOut;
	animation-name: NotificationFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

@-webkit-keyframes NotificationFadeIn {
	0% {
		opacity: 0;
		left: 384px;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}

@keyframes NotificationFadeIn {
	0% {
		opacity: 0;
		left: 384px;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}

@-webkit-keyframes NotificationLeftFadeIn {
	0% {
		opacity: 0;
		right: 384px;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}

@keyframes NotificationLeftFadeIn {
	0% {
		opacity: 0;
		right: 384px;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}

//noinspection ALL
@-webkit-keyframes NotificationFadeOut {
	0% {
		opacity: 1;
		margin-bottom: 16px;
		padding-top: 16px 24px;
		padding-bottom: 16px 24px;
		max-height: 150px;
	}
	100% {
		opacity: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		max-height: 0;
	}
}

//noinspection ALL
@keyframes NotificationFadeOut {
	0% {
		opacity: 1;
		margin-bottom: 16px;
		padding-top: 16px 24px;
		padding-bottom: 16px 24px;
		max-height: 150px;
	}
	100% {
		opacity: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		max-height: 0;
	}
}

/*
	Buttons
 */

.ant-btn {
	display: inline-block;
	//padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-width: 1px;
	background-color: #f4f4f4;
	color: #444;
	border-color: #ddd;
}

.ant-btn-primary {
	color: #fff;
	background-color: #3c8dbc;
	border-color: #367fa9;
}

.crud-table .custom-filter-dropdown .ant-btn {
	margin-right: 10px;
	margin-top: 5px;
}

.ant-form-item-children-icon {
	right: 10px;
	position: absolute;
	top: 5px;
	color: red;
}

.ant-form-explain {
	color: red
}

.ant-row.ant-form-item {
	margin-bottom: 15px;
}

.crudTable td {
	max-width: 100px;
}

.crud-table th {
	min-width: 150px;
}

.crud-table .custom-filter-dropdown {
	background: #fff;
	border: 1px #ccc solid;
	padding: 5px !important;
	border-radius: 3px;
	min-width: 300px;
}

.crud-table .custom-filter-dropdown .ant-btn-primary {
	margin-right: 10px;
	margin-top: 5px;
}

.crud-table .custom-filter-dropdown .ant-select {
	display: block;
	width: 100% !important;
}

.box-solid .box-body:before, .box-solid .box-body:after {
	display: none;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
	word-break: normal !important;
}

.crud-table th, .crud-table table, .ant-table-fixed-right, .ant-table-fixed-right table {
	border-radius: 0 !important;
}

.crudTable, .crud-table, .ant-modal-wrap {
	.form-control.rdw-editor-main {
		height: 100%;
	}
	
	.ant-btn {
		padding: 0 7px;
	}
}

.rdw-editor-main {
	min-height: 150px;
}

.ant-table-fixed {
	table-layout: fixed;
}

.ant-table-tbody > tr > td {
	word-wrap: break-word;
	word-break: break-all;
}

.ant-btn > i, .ant-btn > span {
	display: inline-block;
	-webkit-transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
	transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
	pointer-events: none;
}

button[disabled], html input[disabled] {
	&.ant-switch-loading, &.ant-switch-disabled {
		cursor: not-allowed;
		opacity: 0.4;
		
	}
}
