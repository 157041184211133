.homepage{
	padding: 50px 0;
}
.homepage_logo{
	width: 248px;
	height: 68px;
	background-image: url("https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_pro/workhard_pro/logo_pro.png");
	background-position: center;
	background-size: cover;
	text-align: center;
	margin: 36px auto 0;
}
.homepage_slogan{
	text-align: center;
}
.homepage_slogan h3{
	margin-top: 5px;
}
.homepage_subtitle{
	text-align: center;
	font-size: 36px;
	font-weight: 100;
	color:#222d32;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 10px;
	margin-top:35px;
}
.homepage_about{
	text-align: center;
}
.homepage_about p{
	font-size: 20px;
}
.homepage_about ul{
	list-style: none;
	margin:20px 0 40px;
	overflow: hidden;
}
.homepage_about ul li{
	display: block;
	width: 33%;
	float: left;
	font-size: 16px;
}
.homepage_about ul li:before{
	width: 20px;
	margin:0 auto;
	display: block;
	font: normal normal normal 14px/1 FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 50px;
	margin-bottom: 10px;
}
.homepage_about ul li:nth-child(1):before{
	content: "\f040";
}
.homepage_about ul li:nth-child(2):before{
	content: "\f0e5";
}
.homepage_about ul li:nth-child(3):before{
	content: "\f02d";
}
.homepage_advs{
	margin-top:30px;
}
.homepage_advs .widget-user .widget-user-header{
	height: 90px;
}
.homepage_advs .widget-user-image{
	top: 40px !important;
	width: 90px;
	height: 90px;
	border-radius: 100%;
	background: rgba(0,0,0,0.5);
	border: 3px solid #fff;
}
.homepage_advs .widget-user-image .fa{
	font-size: 40px;
	display: block;
	text-align: center;
	color:#fff;
	margin-top: 20px;
}
.homepage_btn{
	text-align: center;
	margin-top:50px;
}
.homepage_logo{
	text-align: center;
}
.homepage {
	h3, h5 {
		color: rgb(255, 255, 255);
	}
}
