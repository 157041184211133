html,
body {
  height: 100%;
}

.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;
}

.wrap > .container {
  padding: 70px 15px 20px;
}

.jumbotron {
  text-align: center;
  background-color: transparent;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

.not-set {
  color: #c55;
  font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: "\e151";
}

a.desc:after {
  content: "\e152";
}

.sort-numerical a.asc:after {
  content: "\e153";
}

.sort-numerical a.desc:after {
  content: "\e154";
}

.sort-ordinal a.asc:after {
  content: "\e155";
}

.sort-ordinal a.desc:after {
  content: "\e156";
}

.grid-view td {
  white-space: nowrap;
}

.grid-view .filters input,
.grid-view .filters select {
  min-width: 50px;
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav > li > form {
  padding: 8px;
}

.nav > li > form > button:hover {
  text-decoration: none;
}

.grid-view td.action-column {
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.1em;
  max-width: 7em;
}

.grid-view td.project-settings-column {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  text-align: center;
  max-width: 20em;
  word-wrap: break-word;
  font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  font-size: 12px;
  text-align: left;
}

.table-free tr:nth-child(4) td {
  color: darkgreen;
  font-weight: bold;
}

.table-work tr:nth-child(4) td {
  color: orange;
  font-weight: bold;
}

.bg-warning {
  padding: 15px;
  margin-bottom: 15px;
}

.count {
  position: relative;
}

.count input[type=text] {
  padding-left: 40px;
}

.count .label {
  position: absolute;
  top: 10px;
  left: 25px;
}

.navbar-brand img {
  max-height: 40px;
  width: auto;
  margin-top: -10px;
}

.navbar-inverse {
  background-color: #f5f5f5;
  border-color: #ccc;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #ccc;
}

.navbar-inverse .navbar-toggle {
  background: #323238;
}

.navbar-toggle {
  border-color: #323238;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #222;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
  background-color: #f9b013;
  color: #fff;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background: #f9b013;
  color: #fff;
}

.dropdown-menu {
  border-color: #ccc;
}

/* inner navbar in tasks */
.navbar-inner .navbar-collapse {
  margin: 0 -15px;
}

.ft_logo {
  margin-top: -10px;
}

.ft_logo img {
  height: 30px;
  width: auto;
}

.inline-btns > a, .inline-btns > span, .inline-btns > div {
  margin-right: 10px;
}

.inline-btns > a:last-of-type, .inline-btns > span:last-of-type, .inline-btns > div:last-of-type {
  margin-right: 0;
}

.micro-links {
  margin-bottom: 15px;
}

.micro-tasks-list {
  margin-top: 20px;
  font-size: 13px;
}

/* first upper letter in ya.direct title */
#input_1_3:first-letter {
  text-transform: uppercase;
}

.panel-heading {
  overflow: hidden;
}

.ft_copy, .ft_link {
  display: inline-block;
  margin-right: 10px;
}

.table-tasks td:nth-child(2),
.table-tasks td:nth-child(6) {
  white-space: normal;
  font-size: 12px;
}

.top-margin {
  margin-top: 15px;
}

.top_btns {
  margin-top: 10px;
}

.top_btns a {
  display: inline-block;
  margin-left: 10px;
}

.right_menu_items {
  margin-left: 5px;
  background: #f9b013;
  border-radius: 2px;
  padding: 2px 5px;
  font-size: 12px;
  color: #fff;
}

.dropdown-menu {
  font-size: 12px;
}

.dropdown-menu .badge {
  font-weight: normal;
  border-radius: 3px;
  padding: 2px 5px;
  margin-left: 5px;
}

.dropdown-menu > li > a {
  padding: 3px 10px;
}

.dropdown-menu .divider {
  margin: 3px 0;
}

.navbar-inverse .navbar-nav > li > a:hover .right_menu_items, .navbar-inverse .navbar-nav > li > a:focus .right_menu_items {
  color: #222;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > .active > a .right_menu_items, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover .right_menu_items, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus .right_menu_items {
  background-color: #f9b013;
  color: #fff;
}

.navbar-inverse .navbar-nav > .active > a .right_menu_items, .navbar-inverse .navbar-nav > .active > a:hover .right_menu_items, .navbar-inverse .navbar-nav > .active > a:focus .right_menu_items,
.navbar-inverse .navbar-nav > .open > a .right_menu_items, .navbar-inverse .navbar-nav > .open > a:hover .right_menu_items, .navbar-inverse .navbar-nav > .open > a:focus .right_menu_items {
  background: #f9b013;
  color: #fff;
}

/* for admin lte */
.logo-lg {
  text-align: left;
}

.logo-lg img {
  max-height: 45px;
  width: auto;
  display: block;
  margin-top: 8px;
  float: left;
}

.logo-lg span {
  display: inline-block;
  margin-left: 20px;
  font-size: 24px;
  font-weight: 100;
  font-family: 'Roboto', sans-serif;
}

.main-footer {
  overflow: hidden;
}

.ft_logo {
  margin-top: -10px;
}

.ft_logo img {
  height: 30px;
  width: auto;
}

.btn-link {
  color: #fff;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  opacity: .9;
  color: #fff;
}

.notifications-form hr {
  border-color: #222d32;
  margin: 5px 0;
}

.notify_settings_title {
  margin: 10px 0;
}

.direct-chat-messages.all-messages {
  height: auto;
}

//noinspection ALL
.content-wrapper {
  background: #ecf0f5 url('https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_images/lamp.png') right bottom no-repeat;
}

.dl-horizontal dd div.label {
  display: block;
  margin-right: 5px;
  margin-top: 3px;
}

.control-sidebar-menu > li > .collapse {
  padding: 0 15px;
}

.control-sidebar-menu > li > .collapse a {
  color: #b8c7ce;
  font-size: 12px;
  display: block;
}

.control-sidebar-dark .control-sidebar-heading a, .control-sidebar-dark .control-sidebar-subheading a {
  color: #00a65a;
}

.user_list_user {
  clear: both;
  overflow: hidden;
  padding: 1px 4px;
}

.user_list_link {
  float: left;
  margin-right: 20px;
}

.user_list_fullname {
  float: left;
  color: #222d32;
  margin-right: 20px;
}

.user_list_delete {
  float: left;
}

dd .user_list_user:nth-child(odd) {
  background: #d2deda;
}

.user_list_black, .user_list_white {
  width: 7px;
  height: 7px;
  display: inline-block;
  margin: 0 3px;
  border-radius: 100%;
}

.user_list_black {
  background: #111;
  border: 1px solid #0a0a0a;
}

.user_list_white {
  background: #fff;
  border: 1px solid #0a0a0a;
}

.tasks-form-pre {
  clear: both;
  margin: 0 auto 20px auto;
  overflow: hidden;
}

.tasks-form-selectall {
  overflow: hidden;
  clear: both;
  margin: 0 auto 10px;
}

.tasks-form-selectall label {
  font-weight: normal;
}

.tasks-form-selectall input[type=checkbox] {
  margin: 4px 0 0 5px;
  display: block;
  float: right;
}

.sidebar-menu > li > a {
  padding-left: 7px !important;
  padding-right: 0 !important;
}

.sidebar-menu li > a > .pull-right-container {
  right: 0 !important;
}

.inactive {
  display: none;
}

#count_symbols, #count_words, #count_price {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

#count_symbols span, #count_words span, #count_price span {
  font-weight: bold;
}

.to_right div.label {
  float: right;
}

.result_image, .customer_result_image {
  display: block;
  max-width: 300px;
  height: auto;
  margin-bottom: 10px;
}

.customer_result_image {
  max-width: 100%;
}

.mce-tinymce.has-error {
  border: 1px solid red !important;
}

.help-block-error {
  color: red;
}

.btn-extend {
  text-decoration: none !important;
  margin-left: 20px;
}

.tasks-form-pre .pull-right {
  margin-bottom: 5px;
}

.table-billing tr td:nth-child(5) {
  white-space: normal;
}

.progress-description a {
  color: #fff;
}

.progress-description a:hover {
  opacity: .8;
}

.btn-top {
  vertical-align: top !important;
}

.direct-chat-msg.right .direct-chat-text a {
  color: #fff;
  text-decoration: underline;
}

.keys table tr {
  cursor: pointer;
}

.user-support {
  text-align: center;
}

/** new task list */
.tasks-index .task-index {
  background: #fefefe;
  padding: 20px;
  font-size: 16px;
}

.tasks-index .task-index:nth-of-type(odd) {
  background-color: #d0ead5;
}

.tasks-index .task-index:nth-of-type(odd) .panel-default > .panel-heading {
  background: #fff;
}

.task-title {
  font-size: 16px;
}

.task-title a {
  text-decoration: underline;
  color: #232323;
}

.task-left {
  text-align: center;
}

.task-checkbox {
  display: block;
  float: right;
}

.task-checkbox input[type=checkbox] {
  margin-top: 5px;
  margin-left: 10px;
}

.task-btns {
  text-align: center;
}

.task-btns a {
  display: inline-block;
  margin: 0 auto 5px;
}

.task-settings {
  font-size: 14px;
}

.task-user {
  text-align: center;
}

body {
  //font-family: 'PT Sans', sans-serif !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.checker-session-form .help-block,
.trainer-session-form .help-block,
.superkm-session-form .help-block,
.arbitrazh-session-form .help-block,
.corrector-session-form .help-block {
  display: none !important;
}

.checker-session-form .form-group,
.trainer-session-form .form-group,
.superkm-session-form .form-group,
.arbitrazh-session-form .form-group,
.corrector-session-form .form-group {
  margin: 0 !important;
}

.nav > li > form.session-form {
  padding: 7px;
}

.trainer-session-form .btn {
  padding: 6px 9px !important;
}

.task-search {
  width: 100%;
  padding: 8px 11px;
  box-sizing: border-box;
  overflow: hidden;
}

.user-panel > .info > p {
  font-size: 12px;
  font-weight: normal !important;
}

.telegram_btn {
  margin-bottom: 15px;
}

li.telegram_li {
  margin-bottom: 10px;
  font-size: 16px;
}

li.telegram_li img.telegram_img {
  max-width: 280px;
  height: auto;
}

.message-label {
  color: #333;
  font-weight: 400;
  text-transform: lowercase;
  display: inline-block;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 3px;
  line-height: 15px;
  margin-left: 5px;
}

.message-label-default {
  border: 1px solid #777;
  background: #777;
  color: #fff;
}

.message-label-success {
  border: 1px solid #008d4c;
  background: #008d4c;
  color: #fff;
}

.message-label-warning {
  border: 1px solid #e08e0b;
  background: #e08e0b;
  color: #fff;
}

.faq_block {
  background: #fefefe;
  padding: 5px 10px;
  margin-bottom: 20px;
}

#moderatecopytaskform-task_rate .radio {
  display: inline-block;
  margin-right: 20px;
}

#no_form a {
  color: #ffbc3b;
  text-decoration: underline;
}

#no_form a:hover {
  color: #fff;
}

/** task stats **/
.row-group {
  margin-bottom: 15px;
}

.animate-flash {
  -webkit-animation: animate-flash 1s linear infinite;
  animation: animate-flash 1s linear infinite;
}

.interesting_guest_img {
  float: left;
  margin-right: 30px;
}

.interesting_guest_img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
}

.interesting_guest_name {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0 10px;
}

.interesting_guest_text {
  font-size: 14px;
  color: rgba(0, 5, 68, .7);
}

.interesting_guest {
  margin-bottom: 30px;
}

.review_event_text {
  position: relative;
  overflow: hidden;
  float: left;
}

.text-open {
  overflow: visible;
  height: auto;
}

.text-open .toggle_text {
  display: none;
}

.toggle_text {
  background-image: -webkit-linear-gradient(rgba(242, 242, 247, 0) 0%, #f2f2f7 100%);
  background-image: -moz-linear-gradient(rgba(242, 242, 247, 0) 0%, #f2f2f7 100%);
  background-image: -o-linear-gradient(rgba(242, 242, 247, 0) 0%, #f2f2f7 100%);
  background-image: linear-gradient(rgba(242, 242, 247, 0) 0%, #f2f2f7 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.review_event_img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
}

.review_event_name {
  float: left;
  font-size: 20px;
  font-weight: 700;
  margin-right: 40px;
}

.review_event_subtext {
  float: left;
  font-size: 14px;
  color: rgba(0, 5, 68, .7);
  margin-right: 40px;
  margin-top: 5px;
}

.review_event_info {
  float: left;
  font-size: 12px;
  background: #ffd74e;
  padding: 3px 11px;
  border-radius: 20px;
  margin-top: 3px;
}

.review_event_divider {
  border-bottom: 1px solid #dddde1;
  width: 100%;
  float: left;
  margin: 15px 0;
}

a.toggle_text-btn {
  float: left;
}

.review_event {
  margin-bottom: 20px;
  background: #f2f2f7;
  border-radius: 20px;
  padding: 20px;
}

.review_event_text {
  font-size: 16px;
}

.toggle_text-btn {
  font-size: 16px;
  color: #517be5;
}

@media (max-width: 991px) {
  .interesting_guest {
    overflow: hidden;
    margin-bottom: 20px;
  }
  .review_event {
    text-align: center;
  }
}

@-webkit-keyframes animate-flash {
  100% {
    background-color: rgba(249, 2, 5, 0)
  }
}

@keyframes animate-flash {
  100% {
    background-color: rgba(249, 2, 5, 0)
  }
}

.sidebar-menu li.header {
  font-size: 14px;
  color: #f7f7f7 !important;
}

.new_task {
  padding: 8px 5px;
  float: left;
}

.mobile_logo {
  display: none;
}

.additional_forms ul {
  list-style: none;
  margin: 0;
  padding: 5px 10px;
}

.additional_forms ul li {
  display: inline-block;
  padding: 0 2px;
}

.additional_forms ul li button {
  max-width: 40px;
}

@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 50px !important;
  }
  .main-header .logo {
    display: none;
  }
  .navbar-custom-menu > .navbar-nav {
    clear: both;
  }
  .mobile_logo {
    display: block;
    float: right;
    padding: 7px 10px;
  }
}

@media (max-width: 420px) {
  .main-sidebar {
    padding-top: 100px !important;
  }
}

.switch-form {
  label {
    margin: 5px 10px;
  }

  small {
    display: block;
  }

  .switcher {
    display: inline-block;
  }
}

.tests-answer-form {
  ul {
    padding: 0;
  }
}

.tasklist-filter {
  .focused {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .btn-danger .badge {
    color: #fff;
    background-color: #777;
  }

  .btn-app:active .badge {
    background-color: #39cccc;
  }

  .btn-app:focus .badge {
    background-color: #39cccc;
  }
}

.navbar-nav > .user-menu > .dropdown-menu {
  width: auto;
  .btn-toolbar{
    display: flex;
  }
}

.country-flags {
  margin-left: 5px;
  width: 16px;
}

.dimension {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 2px;
  font-size: 10px;
}

.label-item {
  background-color: #f4f4f4;
  color: #444;
  border-radius: 5px;
  border: 1px solid #ddd;
  line-height: 1.5;
  padding: 0 5px 1px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.last-entering-date {
  color: #999;
  font-size: 11px;
  align-self: center;
}


.payment-icons {
  margin-bottom: 10px;
  padding: 0;
  .payment-visa {
    height: 50px;
  }

  .payment-master {
    height: 50px;
  }

  .payment-webmoney {
    height: 50px;
    margin: 0 10px;
  }

  .payment-io {
    height: 30px;
    width: auto;
    margin: 0 10px;
  }
}
